header {
  position: relative;
  height: $header-height;
  -webkit-backface-visibility: hidden;

  @include respond-to($large-min) {
    height: $header-height-large;
  }
}

.header--fixed {
  background-color: $color-background;
  box-shadow: 0px 2px 75px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 50;
  transition: all .3s ease;
}

// Logo
.branding {
  position: absolute;
  top: 20px;
  left: 20px;
  transition: transform .3s ease;

  @include respond-to($medium-min) {
    top: 26px;
    left: 20px;
  }
}

.branding__link {
  color: $color-brand;
  text-decoration: none;

  &:hover, &:focus {
    color: $color-brand;
    text-decoration: none;
  }

  .logo-desktop {
    @include respond-to(0px, $small-max) {
      display: none;
    }
  }

  .logo-mobile {
    @include respond-to($medium-min) {
      display: none;
    }
  }
}

.logo {
  width: 130px;
  height: 24px;
  transform: scale(1);
  transition: transform .3s ease;
}

.logo__outline {
  fill: $color-brand;
}

.branding__title {
  font-size: 16px;

  @include respond-to($large-min) {
    font-size: 20px;
  }
}
