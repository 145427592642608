.button {
  background-color: $color-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto 0px;
  width: 100%;
  max-width: 100%;
  height: 48px;
  border-radius: 5px;
  color: $color-white;
  border: none;
  cursor: pointer;
  font-family: $font-alt;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1;
  transition: background-color .3s ease;

  &:hover, &:focus {
    background-color: #472b5b;
    color: $color-white;
    text-decoration: none;
  }

  @include respond-to($medium-min) {
    width: 365px;
  }
}

button.button {
  span {
    display: block;
    margin: 0px auto;
    width: 100%;
    text-align: center;
  }
}

.button--facebook {
  background-color: #3b5998;

  &:hover, &:focus {
    background-color: #17326a;
  }
}

.button--transparent {
  background-color: transparent;
  color: $color-primary;
  border: 1px solid $color-primary;
  max-width: 335px;
}

.download-button {
  margin-left: 5px;
  margin-right: 5px;

  svg {
    width: 120px;
    height: 38px;
  }

  &:hover {
    .download-button__bgd {
      fill: rgba(0,0,0,.4);
    }

    .download-button__bgd--g {
      fill: rgba(0,0,0,.55);
    }
  }
}

.download-button__bgd {
  fill: rgba(0,0,0,.2);
  transition: fill .3s ease;
}

.download-button__bgd--g {
  fill: rgba(0,0,0,.35);
}

.button--app {
  background-color: $color-white;
  position: relative;
  justify-content: space-between;
  margin: 0px;
  padding-left: 20px;
  padding-right: 20px;
  color: $color-text;
  text-transform: none;

  &:hover, &:focus {
    background-color: $color-white;
    color: $color-text;
  }

  &:after {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 10px;
    height: 10px;
    border: 1px solid #c7c7cc;
    border-width: 1px 1px 0px 0px;
    transform: rotate(45deg) translateY(-50%);
    content: "";
  }
}
