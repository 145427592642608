/*==========  Clearfix  ==========*/
.clear {
  &::before,
  &::after {
    content: '';
    display: table;
  }
  &::after { clear: both; }
}

/*==========  Visibility  ==========*/
.show { display: block !important; }
.hidden { display: none !important; }
.invisible { visibility: hidden; }

.show-small {
    @include respond-to($medium-min) {
        display: none!important;
    }
}

.hide-small {
    @include respond-to(0px, $small-max) {
        display: none!important;
    }
}

/*==========  Alignment  ==========*/
.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }

/*==========  Transform Text  ==========*/
.text-lowercase { text-transform: lowercase; }
.text-uppercase { text-transform: uppercase; }
.text-capitalize { text-transform: capitalize; }

// https://medium.com/@chrisnager/center-and-crop-images-with-a-single-line-of-css-ad140d5b4a87
.object-cover {
  object-fit: cover;
}
