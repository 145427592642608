// Forms
.form__inputs {
  @include respond-to($large-min) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  + .button {

    @include respond-to($large-min) {
      margin-top: 10px;
    }
  }
}

.form__inputs--1col {
  @include respond-to($large-min) {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    justify-content: center;
  }

  .form__row {
    @include respond-to($large-min) {
      width: 100%;
    }
  }
}

.form__inputs--centered {
  @include respond-to($large-min) {
    justify-content: center;
  }
}

.form__row {
  position: relative;
  margin-bottom: 20px;

  @include respond-to($large-min) {
    width: 48%;
  }

  label {
    display: block;
    margin-bottom: 5px;
    font-size: 12px;
    float: left;

    &.optional {
      font-style: italic;
      float: right;
      opacity: .5;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  input[type="date"] {
    width: 100%;
    height: 42px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #e1e1e1;
  }

  select {
    background: #fff;
    width: 100%;
    height: 42px;
    line-height: 42px;
    border: 1px solid #e1e1e1;
  }
}

.form__row--checkbox {
  @include respond-to($large-min) {
    margin-bottom: 0px;
  }

  > label {
    margin-bottom: 15px;
    float: none;
  }
}

.checkboxes {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.checkboxes__item {
  width: 50%;

  label {
    font-size: 16px;
  }

  input[type="checkbox"] {
    margin-right: 5px;
  }
}

.checkboxes__item--single {
  width: 100%;
}

// DOB fields
.form__row--dob {
  .dob-fields {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .dob-fields__field {
    width: 49%;
  }
}

// terms & conditions checkbox
.form__row--agree {
  .agree_check {
    display: inline;
  }

  .agree_label {
  display: inline;
  padding-left: 5px;
  float: none;
  }
}

// Form validation
.parsley-errors-list {
  margin: 10px 0px 0px;
  padding-left: 10px;
  font-size: 12px;
  font-style: italic;
  color: #f00;
  list-style: none;

  li {
    margin: 0px;
    padding: 0px;
  }
}

.form__row {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  input[type="date"],
  select {
    &.parsley-error {
      background-color: $color-alert-danger-bgd;
      color: $color-alert-danger;
      border-color: #eed3d7;
    }

    &.parsley-success {
      background-color: $color-alert-success-bgd;
      color: $color-alert-success;
      border-color: #d6e9c6;
    }
  }

  input:disabled {
    background-image: url(../images/lock.svg);
    background-repeat: no-repeat;
    background-position: right 10px center;
    padding-right: 25px;
    color: rgba(80,80,80,.8);
  }
  }



.form__hint {
  margin-top: 5px;
  font-size: 12px;
  font-style: italic;
}

#ui-datepicker-div {
  font-family: $font-alt;
  .ui-datepicker-header {
    background: $color-primary;
    color:$color-white;
    .ui-corner-all:not(.ui-state-disabled){
      transform-origin: center;
      border: none;
      top:2px;
      background: #dadada url("images/ui-bg_glass_75_dadada_1x400.png") 50% 50% repeat-x;
      font-weight: normal;
      cursor: pointer;
      &:hover{
        border: #222 solid .5px;
        color:#FFF;
      }
    }
    .ui-datepicker-prev {
      left:3px;
    }
    .ui-datepicker-next {
      right:3px;
    }
  }
  th {
    font-family: $font-alt;
  }
}
