#main {
  background-color: $color-background;
  background-image: url(../images/bgd-texture.jpg);
  background-repeat: repeat;
  width: 100%;
  min-height: 100%;
  transition: transform .3s ease;
  margin-bottom: -270px;
  -webkit-backface-visibility: hidden;

  @include respond-to($large-min) {
    margin-bottom: -200px;
  }

  &:after {
    content: "";
    display: block;
    height: 270px;

    @include respond-to($large-min) {
      height: 200px;
    }
  }
}

// general 100% wrapper
.container {
  width: 100%;
  max-width: 1200px;
  position: relative;
  margin: 0 auto;
  padding: 0px 20px;

  @include respond-to($large-min) {
    padding: 0px 40px;
  }
}

.panel--box {
  background-color: $color-white;
  position: relative;
  z-index: 10;
  border-radius: 4px;
  margin: 190px auto 40px;
  padding-bottom: 30px;
  box-shadow: 0px 2px 75px rgba(0,0,0,.15);

  @include respond-to(0px, $medium-max) {
    margin: 130px 15px 40px;
  }

  @include respond-to($large-min) {
    max-width: 600px;
  }

  @include respond-to($xlarge-min) {
    max-width: 790px;
  }

  h1 {
    margin-top: 30px;
    margin-bottom: 40px;
    text-align: center;
    color: $color-brand;
  }

  h2 {
    color: $color-brand;
  }

  .container {
    padding: 0px 15px;

    @include respond-to($large-min) {
      padding: 0px 60px;
    }
  }

  + .panel--box {
    margin-top: 10px;
  }
}

.alert {
  text-align: center;
  color: $color-primary;
  font-style: italic;

  @include respond-to($large-min) {
    top: 140px;
  }

  p {
    margin-bottom: 0px;
  }

  li {
    margin: 0px auto;
    padding: 0px;
    list-style: none;
  }
}
