// Hero section
.hero {
  background-position: center center;
  background-size: cover;
  position: relative;

  .container {
    text-align: center;
  }
}

.bgd-split {
  background-size: cover;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0px;
  // height: 228px;
  height: 793px;

  // @include respond-to($large-min) {
  //   height: 793px;
  // }
}

.bgd-split--full {
  height: 100vh;
}
