.header-container {
  position: fixed;
  top: $header-height;
  bottom: 0px;
  right: 0px;
}

.navigation {
  text-align: right;
  text-align: left;
  transform: translateX(280px);
  transition: all .3s ease;
}

.navigation__container {
  background-color: $color-primary-dark;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 280px;
  height: 100%;
  transition: all 1.25s;
  overflow-y: scroll;
}

.navigation__list {
  display: inline-block;
  flex-shrink: 0;
  margin: 20px 0px 0px;
  padding: 0px;
  font-family: $font-alt;
  font-size: 16px;

  @include respond-to($small-min) {
    margin-top: 40px;
  }

  &:last-child {
    margin-bottom: 20px;

    @include respond-to($small-min) {
      margin-bottom: 60px;
    }
  }
}

.navigation__item {
  margin: 0px auto;
  width: 100%;
  display: inline-block;

  a {
    display: block;
    padding: 10px;
    padding-left: 40px;
    color: $color-white;
    font-weight: normal;
    text-decoration: none;
    text-transform: uppercase;

    @include respond-to(0px, $small-max) {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    @include respond-to($large-min) {
      transform: translateX(300px);
    }

    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}

.navigation__auth {
  a {
    font-weight: bold;
  }
}

// Mobile navigation
.mobile-nav-button {
  position: absolute;
  top: 29px;
  right: 15px;
  width: 40px;
  height: 25px;
  cursor: pointer;
  opacity: 1;
  transition: transform .3s ease .1s;
  -webkit-tap-highlight-color: transparent;

  @include respond-to($large-min) {
    right: 60px;
  }
}

.mobile-nav-line {
  background-color: $color-primary;
  position: absolute;
  width: 40px;
  height: 3px;
  border-radius: 1px;
  transform: rotate(0deg) translateX(0) translateY(0);
  transition: all .2s ease;

  &.mobile-nav-top {
    top: 0px;
    transform-origin: left top;
  }
  &.mobile-nav-mid {
    top: 11px;
  }
  &.mobile-nav-bottom {
    bottom: 0px;
  }
}

.js-nav-visible {
  overflow: hidden;

  .navigation {
    transform: translateX(0);
  }

  .mobile-nav-button {
    transform: rotate(135deg);
  }

  .mobile-nav-top {
    transform: rotate(90deg) translateX(-6px) translateY(-21px);
  }

  .mobile-nav-mid {
    opacity: 0;
  }

  .mobile-nav-bottom {
    transform: translateY(-10px);
  }

  .navigation__item {
    a {
      opacity: 0;

      @include respond-to(0px, $medium-max) {
        animation: .3s navFade ease;
        animation-fill-mode: forwards;
      }

      @include respond-to($large-min) {
        animation: .3s navSlideIn ease;
        animation-fill-mode: forwards;
      }
    }

    $children: 6;
    @for $i from 1 through $children {
      &:nth-child(#{$i}) {
        a {
          animation-delay: #{$i * 80}ms;
        }
      }
    }
  }

  #main, footer {
    transform: translateX(-280px);
  }

}

// Account navigation
.account-nav {
  position: absolute;
  top: 30px;
  right: 50px;
  margin: 0px;
  padding: 0px;
  list-style: none;
  font-size: 0px;
  transition: transform .3s ease;

  @include respond-to($large-min) {
    right: 120px;
  }
}

.account-nav__item {
  display: inline-block;
  margin: 0px 30px 0px 0px;
  padding: 0px;

  &.active {
    > .account-nav__link {
      .account-nav__icon {
        stroke: $color-brand;
      }
    }
  }
}

.account-nav__link {
  display: block;
}

.account-nav__icon {
  stroke: #999;
  transition: all .3s ease;
}

.icon-account__head {
  fill: rgba(255,255,255,.0);
  transition: all .3s ease;
}

.icon-account {
  &:hover, &:focus {
    .icon-account__head {
      fill: rgba(153,153,153,1);
      stroke: none;
    }
  }
}

.icon-cards {
  .icon-cards__top-card {
    transition: transform .3s ease;
  }

  &:hover, &:focus {
    .icon-cards__top-card {
      transform: rotate(8deg) translateX(2px);
    }
  }
}

.icon-settings {
  &:hover, &:focus {
    transform: rotate(210deg);
  }
}

@keyframes navSlideIn {
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes navFade {
  100% {
    opacity: 1;
  }
}

@keyframes blur {
  0%, 20% {
    -webkit-filter: blur(0px);
  }
  50% {
    -webkit-filter: blur(5px);
  }
  80%, 100% {
    -webkit-filter: blur(0px);
  }
}
