/**
*
* Typography
*
**/

html, body { color: $color-text; }

// Normalizing text
// Recommend using padding instead of margin
h1, h2, h3, h4, h5, h6, p { margin: 0px; }

// Definitions
.small,
small { font-size: $font-small; }

.base { font-size: $font-base; }

p {
  margin: 10px 0px;
}

h1 {
  font-family: $font-alt;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
}

h2 {
  font-family: $font-alt;
  font-size: 45px;
  font-weight: 400;
  line-height: 1;

  @include respond-to($large-min) {
    font-size: 35px;
  }
}

h3 {
  font-family: $font-alt;
  font-size: 16px;
  font-weight: 400;
  color: $color-accent;
  line-height: 1;

  @include respond-to($large-min) {
    font-size: 24px;
  }
}

h4 {
  font-family: $font-alt;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}

h5 {
  font-family: $font-alt;
  font-size: 16px;
  font-weight: bold;
}

h6 {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

li > p { padding-top: 0px; }

a {
  transition: color .3s ease;

  &:hover { color: $color-text-link-hover; }
}

.list--alpha {
  list-style-type: lower-alpha;
}
