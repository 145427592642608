.modal {
  background-color: rgba(0,0,0,.9);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: -100;
  padding: 40px;
  opacity: 0;
  overflow-y: scroll;
  transform: scale(.75);
  transition: transform .3s ease, opacity .3s ease;
}

.modal__container {
  background-color: $color-background;
  position: relative;
  margin: 0px auto;
  padding: 30px;
  text-align: center;

  @include respond-to($medium-min) {
    padding: 40px;
  }
}

.js-modal-visible {
  overflow: hidden;
}

.js-modal-shown {
  opacity: 1;
  z-index: 1000;
  transform: scale(1);
}

.modal__close-button {
  background-color: transparent;
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: $color-white;
  overflow: hidden;
  opacity: 1;
  transition: opacity .3s ease;
  outline: none;

  &:hover, &:focus {
    opacity: .7;
  }

  svg {
    fill: $color-white;
    pointer-events: none;
  }
}
