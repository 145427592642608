footer {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  height: 340px;
  transition: transform .3s ease;
  -webkit-backface-visibility: hidden;

  @include respond-to($large-min) {
    height: 220px;
  }

  .download-buttons {
    margin-bottom: 15px;
  }
}

.footer__top {
  padding: 25px 0px;
  color: $color-white;
  overflow: hidden;
}

.download-buttons {
  font-size: 0px;
}

.footer__bottom {
  background-color: $color-grey;
  color: $color-white;
  overflow: hidden;

  p {
    margin: 0px auto 10px;
    font-size: 12px;

    @include respond-to($large-min) {
      font-size: 9px;
    }
  }

  a {
    color: $color-white;
    text-decoration: none;
    border-bottom: 1px solid $color-white;
  }
}

.footer__nav {
  margin: 10px auto 10px;
  padding: 0px;
  list-style: none;

  li {
    display: inline-block;
    margin: 0px 8px;
    font-size: 16px;

    @include respond-to($large-min) {
      font-size: 12px;
    }
  }
}

.footer__title {
  color: $color-dark-grey;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
}
