/**
*
* Basic site elements
*
**/

// https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
html, body {
  width: 100%;
  height: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  background-color: $color-primary-dark;
  font-family: $font-default;
  font-size: 15px;
  font-weight: 400;
    text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
  color: $color-text;

  @include respond-to($medium-min) {
      font-size: 16px;
  }
}

a {
  color: $color-text-link;
  // font-weight: 300;
  transition: .2s color ease;

  &:hover, &:focus {
    color: $color-text-link-hover;
    text-decoration: underline;
  }
}

//==============================================================================
// Responsive YouTube Container
// http://avexdesigns.com/responsive-youtube-embed/
//==============================================================================
.yt-video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 1.875em;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
   }
}
